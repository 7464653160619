_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Geef de bijpassende waarde op voor {0}",
	"crm.label.field":"Veld",//no i18n
"crm.label.value":"Waarde",//no i18n
	"sentiment.criteria.wrongcriteria":"Waarde voor criterium kan niet meer zijn dan {0}",//no i18n
"crm.mb.field.common.splc":"Speciale tekens zijn niet toegestaan. Voer een geldige waarde in.",//no i18n
	"crm.label.field.plural":"velden in",//no i18n
	"crm.label.in.minutes":"{0} (in minuten)",//no i18n
	"crm.security.roles.list":"Rollijst",//no i18n
"crm.security.roles.lookup.info":"Selecteer minstens een rol uit de lijst.",//no i18n
"crm.territory.addterritory":"Rayon toevoegen",//no i18n
"crm.title.edit.territory":"Rayon bewerken",//no i18n
"crm.territory.title.assign.territories":"Rayons toewijzen",//no i18n
	"crm.label.context.help":"Hulp",//no i18n
	"crm.label.from":"Van",//no i18n
"crm.label.to":"naar",//no i18n
	"workflow.option.webhookFailure.fromDate":"Begindatum",//no i18n
"workflow.option.webhookFailure.toDate":"Einddatum",//no i18n
"crm.custom.field.less.than.equalto":"{0} moet minder dan of gelijk aan {1} zijn.",//no i18n
	"crm.template.listview.search.no.results":"Geen resultaten gevonden",//No I18n
	"crm.label.tag.new":"Nieuwe tag",//No I18n
	"crm.label.enter.tag":"Tags invoeren",//No I18n
	"crux.comboBox.max.limit":"U kunt niet meer dan {0} {1} selecteren.",//NO I18n
	"crm.wf.usage.date.criteria.error.msg":"Begindatum kan niet later vallen zijn dan de einddatum.",//no i18n
	"Administrator":"Beheerder",//No I18n
	"Standard":"standaard",//No I18n
	"crm.button.add":"Toevoegen",//NO I18n
	"crm.label.users":"Gebruikers", //NO I18n
  "crm.workflow.alert.roles":"Rollen", //NO I18n
  "crm.security.groups":"Groepen", //NO I18n
	"crm.label.available" : "Beschikbaar", //NO I18n
	"crm.label.assign.manually" : "Toewijzen", //NO I18n
	"crm.globalsearch.option.all": "Alle", //NO I18n
	"webform.status.Active":"Actief", //NO I18n
	"Inactive":"Inactief", //NO I18n
  "Confirmed":"Bevestigd", //NO I18n
  "crm.user.component.unconfirmed":"Niet bevestigd",//no i18n
  "DeletedUser":"Verwijderd", //NO I18n
  "crm.feed.group.admin":"Beheerder", //NO I18n
  "crm.ln.lable.current":"Huidig", //NO I18n
	"crm.label.selected": "Geselecteerd",//NO I18n
	"crm.auditlog.user": "Gebruiker", //NO I18n
	"cob.role": "Rol", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Profiel", //NO I18n
	"crm.security.group.users.empty": "Geen gebruikers gevonden.", //NO I18n
	"crm.label.picklist.none": "Geen", //NO I18n
	"crm.usrpop.non.selected" : "Geselecteerde gebruikers",//NO I18n
	"crm.zti.label.user": "Gebruikersnaam", //NO I18n
	"crm.label.notSelected" : "Niet geselecteerd",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Oproep",//NO I18n
	"crm.phoneNo.Link.Title" : "Bellen via Skype",//NO I18n
	"crm.no.data.found" : "Geen gegevens gevonden.",//NO I18n
	"crm.label.no.options.found" : "Geen opties gevonden.",//No I18n
	"crm.globalsearch.search.title" : "Zoeken",//No I18n
	"None" : "Geen",//No I18n
	"crm.label.criteria.pattern" : "Criteria Patroon",//No I18n
	"crm.label.edit.criteria.pattern" : "Patroon bewerken",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Patroonhaakjes komen niet overeen.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Ongeldige haakjes rond de voorwaardeoperator(s).",//No I18n
	"crm.criteria.number.notmatch.check" : "Controleer het patroon in {0}.",//No I18n
	"criteria.error.alert.other.params" : "Ongeldige inhoud in dit patroon.", //No I18n
	"crm.label.search.for.users": "Gebruikers zoeken", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Het criteriapatroon komt niet overeen met de door u gekozen voorwaarden.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Het criteriapatroon komt niet overeen met de door u gekozen voorwaarden.", //No I18n
	"and" : "en", //No I18n
	"or" : "of", //No I18n
	"crm.label.or" : "OF", //No I18n
	"crm.label.and" : "EN", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Voer een geldig veldlabel in voor de rij {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Geef een geldige voorwaarde op voor {0}.", //No I18n
	"crm.field.valid.check" : "Voer een geldig(e) {0} in.", //No I18n
	"crm.custom.field.less.than.to" : "<i>Van</i>-bereik kan niet groter zijn dan <i>Naar</i>-bereik.", //No I18n
	"crm.alert.label.savepattern" : "Sla het patroon op voordat u criteria wijzigt.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "U kunt geen extra criteria toevoegen.",//No I18n
	"is" : "is",//No I18n
	"isn\'t" : "is niet",//No I18n
	"contains" : "Bevat",//No I18n
	"doesn\'t contain" : "Bevat geen",//No I18n
	"starts with" : "Start met",//No I18n
	"ends with" : "Eindigt met",//No I18n
	"is empty" : "is leeg",//No I18n
	"is not empty" : "is niet leeg",//No I18n
	"is before" : "is voor",//No I18n
	"is after" : "is na",//No I18n
	"between" : "tussen",//No I18n
	"not between" : "niet tussen",//No I18n
	"Today" : "Vandaag",//No I18n
	"Tommorow" : "Morgen",//No I18n
	"Tommorow Onwards" : "Vanaf morgen",//No I18n
	"Yesterday" : "Gisteren",//No I18n
	"Till Yesterday" : "Tot gisteren",//No I18n
	"Last Month" : "Vorige Maand",//No I18n
	"Current Month" : "Huidige maand", //No I18n
	"Next Month" : "Volgende Maand", //No I18n
	"Last Week" : "Vorige Week", //No I18n
	"Current Week" : "Huidige Week", //No I18n
	"Next Week" : "Volgende Week", //No I18n
	"Age in Days" : "Leeftijd in Dagen", //No I18n
	"Due in Days" : "Verloopt over dagen", //No I18n
	"Scheduled" : "Gepland", //No I18n
	"Attended Dialled" : "Aangenomen gebeld", //No I18n
	"Unattended Dialled" : "Niet aangenomen gebeld", //No I18n
	"Overdue" : "Achterstallig", //No I18n
	"Cancelled" : "Geannuleerd", //No I18n
	"Received" : "Ontvangen", //No I18n
	"Missed" : "Gemist", //No I18n
	"crm.alert.character.not.allowed" : "{0} is niet toegestaan", //No I18n
	"crm.condition.in.last" : "in de afgelopen", //No I18n
	"crm.zinvoice.dueIn" : "vervalt na", //No I18n
	"on" : "Op",//No I18n
	"before" : "voor",//No I18n
	"crm.label.general.small.after" : "na",//No I18n
	"crm.thisweek" : "Deze week",//No I18n
	"crm.label.this.month" : "Deze maand",//No I18n
	"crm.thisyear" : "Dit jaar",//No I18n
	"crm.source.user.and.system" : "Gebruiker en systeem",//No I18n
	"crm.source.user.or.system" : "Gebruiker of systeem",//No I18n
	"crm.label.system2" : "Systeem",//No I18n
	"crm.source.user.only" : "Alleen door gebruiker",//No I18n
	"crm.source.system.only" : "Alleen door systeem",//No I18n
	"crm.condition.till.today" : "Tot vandaag",//No I18n
	"game.month.left" : "1 maand",//No I18n
	"game.months.left" : "{0} maanden",//No I18n
	"crm.condition.last.30.days" : "in de afgelopen 30 dagen",//No I18n
	"crm.condition.last.60.days" : "in de afgelopen 60 dagen",//No I18n
	"crm.condition.last.90.days" : "in de afgelopen 90 dagen",//No I18n
	"crm.label.filter.typehere" : "Hier typen", //No I18N
	"crm.filter.is.not" : "is niet", //No I18n
	"crm.condition.until.now" : "Tot nu",//No I18n
	"crm.filter.email.isblocked" : "is geblokkeerd",//No I18n
	"crm.filter.email.isnotblocked" : "is niet geblokkeerd",//No I18n
	"crm.label.no.results.match" : "Geen overeenkomstige resultaten",//No I18n
	"crm.label.select.user" : "Klikken om gebruikers te selecteren", //No I18n
	"current.logged.in.user": "Aangemelde gebruiker", //NO I18n
	"current.logged.in.user.definition": "De gebruiker die de recordhandeling opstart.", //NO i18n
	"crm.security.group": "Groep", //NO I18n
	"crm.security.role": "Rol", //NO I18n
	"Date" : "Datum",//No I18n
	"crm.field.valid.decimal.check2" : "Aantal decimalen voor het veld <i>{0}</i> moet minder dan of gelijk zijn aan {1}.",//No I18n
	"crm.field.empty.check" : "{0} mag niet leeg zijn.",//No I18n
	"crm.label.add.note": "Aantekening toevoegen", //NO I18n
	"crm.label.simply.by": "door", //NO I18n
	"crm.general.addnote": "Een notitie toevoegen", //NO I18n
	"crm.general.addtitle": "Een titel toevoegen", //NO I18n
	"crm.label.attach.file": "Bijgevoegde File", //NO I18N
	"crm.button.cancel": "Annuleren", //NO I18N
	"crm.button.save": "Opslaan", //NO I18N
	"crm.button.mass.delete": "Verwijderen", //NO I18N
	"crm.warning.delete.record": "Weet u zeker dat u \"{0}\" naar de prullenbak wilt verplaatsen?", //NO I18N
	"crm.label.yes": "Ja", //NO I18N
	"crm.note.view.previous": "Vorige notities weergeven", //NO I18N
  "of": "van", //NO I18N
	"crm.label.notes": "Notities", //NO I18N
	"crm.note.recent.first": "Eerste recent", //NO I18N
	"crm.note.recent.last": "Laatste recent", //NO I18N
	"crm.territory.label.only": "alleen {0}", //no i18n
	"crm.select" : "Selecteren",//No I18n
	"crm.button.apply.filter" : "Filter toepassen",//No I18n
	"crm.alert.maximum.text.values.contains" : "U kunt niet meer dan {0} waarden voor dit veld invoeren.",//No I18n
	"PM" : "PM",//No I18n
	"crm.mb.newversion.msg4" : "OK, ik begrijp het.",//No I18n
	"Jan" : "Jan",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "Mrt",//No I18n
	"Apr" : "Apr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Aug",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Okt",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dec",//No I18n
	"crm.label.More" :"Meer", //no i18n

	"crm.label.unmapped.stages":"Onverantwoord", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Meer weergeven",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Minder weergeven",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"WAARSCHIJNLIJKHEID",//no i18n
"crm.lead.prediction.tooltip.score":"SCORE",//no i18n
"Planned":"Gepland",//no i18n
"Invited":"Uitgenodigd",//no i18n
"Sent":"Verzonden",//no i18n
"Received":"Ontvangen",//no i18n
"Opened":"Geopend",//no i18n
"Responded":"Beantwoord",//no i18n
"Bounced":"Geweigerd",//no i18n
"Opted\ Out":"Opgezegd",//no i18n
"crm.filter.label.with.open":"Met open {0}",//no i18n
"crm.filter.label.without.open":"Zonder open {0}",//no i18n
"crm.filter.label.without.any":"Zonder enige {0}",//no i18n
"crm.filter.label.with.module":"Met {0}",//no i18n
"crm.filter.label.activity.due":"{0} verlopen",//no i18n
"crm.filter.label.activity.done":"{0} Voltooid",//no i18n
"Notes":"Notities",//no i18n
"crm.filter.label.notes.added":"Opmerkingen toegevoegd",//no i18n
"crm.label.filter.email.status":"Status meest recente e-mail",//no i18n
"crm.label.filter.email.clicked":"geklikt",//no i18n
"crm.label.filter.email.responded":"gereageerd", //no i18n
"crm.label.filter.email.info":"Filter records op basis van de laatste status van uw verzonden/ontvangen e-mails.",//no i18n
"crm.filter.label.sent":"verzonden",//no i18n
"crm.filter.label.not.sent":"niet verzonden",//no i18n
"crm.filter.label.opened":"geopend",//no i18n
"crm.filter.label.not.opened":"niet geopend",//no i18n
"crm.filter.label.received":"ontvangen",//no i18n
"crm.filter.label.not.received":"niet ontvangen",//no i18n
"crm.filter.label.bounced":"Niet bezorgd",//no i18n
"crm.filter.label.opened.not.replied":"geopend en niet beantwoord", //no i18n
"crm.filter.label.any":"Een van het bovenstaande",//no i18n
"crm.zia.config.potential.amount":"Bedrag van {0}",//no i18n
"Quote\ Stage":"{0} Fase",//no i18n
"crm.module.owner":"{0}-eigenaar",//no i18n
"Potential\ Closing\ Date":"Sluitingsdatum van {0}",//no i18n
"crm.lead.prediction.likely.convert":"Wordt waarschijnlijk geconverteerd",//no i18n
"crm.lead.prediction.convert.high":"Hoog",//no i18n
"crm.lead.prediction.convert.medium":"Medium",//no i18n
"crm.lead.prediction.convert.low":"Laag",//no i18n
"crm.predictions.feature.label":"Voorspelling",//no i18n
"crm.intelligence.prediction.likelywin":"Waarschijnlijk te winnen",//no i18n
"crm.intelligence.prediction.likelylose":"Waarschijnlijk te verliezen",//no i18n
"crm.intelligence.prediction.halfchance":"50:50 Kans",//no i18n
"crm.intelligence.prediction.score":"Score voorspelling",//no i18n
"crm.lead.prediction.recent.score":"Score van recente voorspelling",//no i18n
"crm.intelligence.prediction.lastconv":"Laatste 3 conversaties",//no i18n
"crm.intelligence.prediction.recordsfocus":"Records om te focussen",//no i18n
"crm.intelligence.prediction.slowmoving":"Weinig voortgang",//no i18n
"crm.intelligence.prediction.trend.down":"Met recente dalende trend",//no i18n
"crm.label.touched.records":"Bewerkte records",//no i18n
"crm.label.untouched.records":"Niet-bewerkte records",//no i18n
"crm.label.record.action":"Actie voor record",//no i18n
"workflow.rule.view.label.Modified":"Gewijzigd",//no i18n
"crm.label.not.modified":"Niet gewijzigd",//no i18n
"crm.label.related.records.action":"Actie voor gerelateerde records",//no i18n
"Done":"Gereed",//no i18n
"crm.label.not.done":"Niet uitgevoerd",//no i18n
"sentiment.model":"Gevoel van e-mail",//no i18n
"sentiment.criteria.count":"Aantal",//no i18n
"sentiment.criteria.percentage":"Percentage",//no i18n
"sentiment.criteria.lastmail":"Voor de laatste e-mail",//no i18n
"Chats":"Chats",//no i18n
"Attended":"Deelgenomen",//no i18n
"crm.lead.prediction.popup.text":"Wanneer Geneigd tot conversie is {0}, moet de score voor voorspelling vallen tussen {1}.",//no i18n
"crm.lead.prediction.popup.final":"Wijzig de filter en probeer het opnieuw.",//no i18n
"crm.custom.field.less.than.to1":"Het Van-bereik kan niet groter zijn dan Naar-bereik.",//no i18n
"Last\ Activity\ Date":"Datum laatste activiteit",//no i18n
"crm.label.vendor.name":"{0}naam",//no i18n
"days":"dagen",//no i18n
"weeks":"weken",//no i18n
"months":"maanden",//no i18n
"crm.label.general.small.after":"na",//no i18n
"Last\ Week":"Vorige Week",//no i18n
"Last\ Month":"Vorige Maand",//no i18n
"crm.module.name":"{0}naam",//no i18n
"Campaign":"Campagne",//no i18n
"Tasks":"Taken",//no i18n
"Calls":"Oproepen",//no i18n
"Events":"Gebeurtenissen",//no i18n
"sentiment.criteria.wrongcriteria":"Waarde voor criterium kan niet meer zijn dan {0}",//no i18n
"crm.chosen.minimum.input.text":"Voer {0} of meer tekens in",//no i18n
"crm.intelligence.prediction.trendup":"Stijgende trend",//no i18n
"crm.intelligence.prediction.trenddown":"Dalende trend",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Voltooid" ,//no i18n
"crm.label.success":"Succes" ,//no i18n
"crm.label.Failure":"Fout" ,//no i18n
"Both":"Beide" ,//no i18n
"crm.condition.cannot.empty":"Voorwaarde mag niet leeg zijn.",//no i18n
"crm.condition.last.30.days":"in de afgelopen 30 dagen",//no i18n
"crm.condition.last.60.days":"in de afgelopen 60 dagen",//no i18n
"crm.condition.last.90.days":"in de afgelopen 90 dagen",//no i18n
"crm.sentiment.Positive":"Positief",//no i18n
"crm.sentiment.Negative":"Negatief",//no i18n
"sentiment.positiveandnegative":"Positief en negatief",//no i18n
"sentiment.positiveornegative":"Positief of negatief",//no i18n
"sentiment.positiveonly":"Alleen positief",//no i18n
"sentiment.negativeonly":"Alleen negatief",//no i18n
"crm.sentiment.Neutral":"Neutraal",//no i18n
"crm.filters.select.campaign.type":"Selecteer {0}-type",//no i18n
"crm.filters.select.campaign.status":"Selecteer {0}-status",//no i18n
"campaign.Member" : "Lid",//no i18n
	"Service":"Service",//no i18n
"Activities":"Activiteiten",//no i18n
"crm.livedesk.pot.nextdays":"Volgende {0} dagen",//no i18n
"Today\ +\ Overdue":"Vandaag + achterstallig",//no i18n
"crm.source.user.and.system":"Gebruiker en systeem",//no i18n
"crm.source.user.or.system":"Gebruiker of systeem",//no i18n
"User":"Gebruiker",//no i18n
"crm.source.user.only":"Alleen door gebruiker",//no i18n
"crm.source.system.only":"Alleen door systeem",//no i18n
"Scheduled":"Gepland",//no i18n
"Attended\ Dialled":"Aangenomen gebeld",//no i18n
"Unattended\ Dialled":"Niet aangenomen gebeld",//no i18n
"Cancelled":"Geannuleerd",//no i18n
"crm.filter.email.isblocked":"is geblokkeerd",//no i18n
"crm.filter.email.isnotblocked":"is niet geblokkeerd",//no i18n
"condition.till.now":"Tot nu",//no i18n
"crm.recurring.no.months":"{0} maanden",//no i18n
"crm.lead.prediction.tooltip":"Wordt waarschijnlijk geconverteerd - Scorebereik",//no i18n
"crm.website.activity":"Activiteit van website",//no i18n
"crm.label.By":"Door",//no i18n
"crm.chosen.searching.text":"Bezig met zoeken...",//no i18n
"crm.label.memberstatus.is":"en status van lid is",//no i18n
"crm.events.duration":"Duur",//no i18n
"crm.title.clear.name":"Wissen",//no i18n
"crm.label.status.is":"en status is",//no i18n
"zia.last3.help":"Conversatie omvat oproepen, taken, {0}, ontvangen e-mails, notities, bezoeken, opmerkingen op sociale media en aanvragen voor ondersteuning vanuit Desk.",//no i18n
"crm.label.tag.related.to":"gerelateerd aan",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Nieuw {0} wordt aangemaakt.",//No I18n
	"crm.krp.no.records.found" : "Geen {0} gevonden",//No I18n
	"crm.module.new" : "Nieuw(e) {0}",//No I18n
	"crm.label.view" : "Weergeven",//No I18n
	"crm.nsocial.customers" : "Klanten",//No I18n
	"crm.nsocial.open.potential" : "{0} openen",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Andere",//No i18n
	"crm.field.length.check" : "{0}-waarde overschrijdt de maximale lengte.", //No I18n
	"crm.lower.now":"nu",//no i18n
	"crm.time.min.ago":"{0} minuten geleden",//no i18n
	"crm.time.mins.ago":"{0} minuten geleden",//no i18n
	"crm.time.hr.ago":"{0} uur geleden",//no i18n
	"crm.time.hrs.ago":"{0} uur geleden", //no i18n
	"AllUsers": "Alle gebruikers", //no i18n
	"crm.label.search":"Zoeken",//no i18n
	"crm.api.filterby":"Filteren op",//no i18n
	"crm.customview.nofields.found":"--Geen overeenkomende velden--",//no i18n
	"crm.setup.system.ziarecommendation":"Aanbeveling",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//no i18n
	"crm.filter.label.select.products":"{0} geselecteerd",//no i18n
	"crm.reviewprocess.smart.filter":"Proces van beoordeling Record Status",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Kies a.u.b. een waarde.",//no i18n
	"crm.segmentation.segment.score":"Segment Score",//no i18n
	"crm.filter.label.in":"gebaseerd op",//no i18n
	"crm.filter.label.and.purchase.in":"en waarschijnlijk kopen in",//no i18n
	"crm.filter.label.last.purchased":"en recent verkregen",//no i18n
	"crm.filter.label.a.day":"een Dag",//no i18n
	"crm.filter.label.a.week":"een Week",//no i18n
	"crm.filter.label.a.month":"een Maand",//no i18n
	"crm.cal.custom":"Aangepast",//no i18n
	"crm.mb.field.common.empt":"De waarde mag niet leeg zijn.",//no i18n
	"crm.chosen.error.loading.text":"We konden uw resultaten niet laden",//no i18n
	"crm.filter.label.firstbuy":"Eerste keer",//no i18n
	"crm.filter.label.cwbab":"Afhankelijk",//no i18n
	"crm.filter.label.fbt":"Bundel",//no i18n
	"crm.filter.label.rebuy":"Herhalen",//no i18n
	"crm.filter.label.nextbuy":"Volgorde",//no i18n
	"crm.mxnlookup.select" : "{0} toewijzen",//No I18n
	"crm.lookup.chooserecord":"{0} kiezen",//no i18n
	"crm.record.selected":"Geselecteerd {0}",//no i18n
	"crm.module.empty.message" : "Geen {0} gevonden",//No I18n
	"crm.mxnlookup.selected" : "Toegewezen {0}",//No I18n
	"crm.security.error" : "U heeft niet voldoende machtigingen om deze bewerking uit te voeren. Neem contact op met uw beheerder.", //No I18n
	"crm.label.creator.noPermission" : "Toestemming geweigerd", //No I18n
	"crm.segmentation.recency" : "Recency", //No I18n
	"crm.segmentation.frequency" : "Frequentie", //No I18n
	"crm.segmentation.monetary" : "Financieel", //No I18n
	"crm.smartfilter.related.module.msg" : "U kunt niet meer dan drie gerelateerde modules selecteren." , //No I18n
	"crm.smartfilter.related.module.msg1" : "(Bijv.: E-mail, activiteiten, notities)" , //No I18n
	"crm.smartfilter.related.module.msg2" : "Duur kan niet leeg zijn", //No I18n
	"crm.label.timeZone": "Tijdzone", //NO I18n
	"crm.label.insufficient.privileges": "Onvoldoende machtigingen om deze actie uit te voeren. Neem contact op met uw beheerder.", //NO I18n
	"crm.filter.header.secton.system": "Door het systeem gedefinieerde filters", //NO I18N
	"crm.filter.header.secton.fields": "Filteren op velden", //NO I18N
	"crm.createfield.calcinfo.new" : "Dit veld berekent elke expressie die u invoert.</br> <b>Bijv. 20+20</b> geeft automatisch <b>40</b>",//No i18n
	"crm.lable.read.only" : "Alleen-lezen veld",//No i18n
	"crm.column.sort.asc" : "Toenemend",//No I18n
	"crm.column.sort.desc" : "Aflopend",//No i18n
	"crm.column.unsort" : "Sortering verwijderen",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Delen met klant", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Slechts {0} tekens zijn toegestaan voor {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Gedeeld met klant", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "Deze rol is al geselecteerd", //no i18n
	"crm.user.deleted": "GEBRUIKER IS VERWIJDERD",  //NO I18N
	"crm.account.closed": "DEZE ACCOUNT IS GESLOTEN",  //NO I18N
	"crm.start.chat": "Chat starten",  //NO I18N
	"crm.start.call": "Een oproep starten",  //NO I18N
	"crm.recipient.invalid.email" : "Ongeldige e-mailadressen gevonden.", //NO I18N
    "crm.recipient.add.recipient" : "Aanvullende ontvanger toevoegen", //NO I18N
	"crm.start.video.call": "Een video-oproep starten",  //NO I18N //ignorei18n_start

	"Score":"Score",
	"Positive Score":"Positieve score",
	"Negative Score":"Negatieve score",
	"Touch Point Score":"Touch Point-score",
	"Positive Touch Point Score":"Positieve Touch Point-score",
	"Negative Touch Point Score":"Negatieve Touch Point-score",
	"crm.label.scoring.rules":"Scoringregels",
	"crm.label.type.minutes": "Typ hier minuten", //NO I18N

	"is\ OPEN":"is OPENSTAAND",//no i18n
	"is\ WON":"is GEWONNEN",//no i18n
	"is\ LOST":"is VERLOREN",//no i18n
	"crm.potential.all.open":"Alle openstaande fases",//no i18n
	"crm.potential.all.won":"Alle afgesloten gewonnen fases",//no i18n

	"crm.potential.all.lost":"Alle afgesloten verloren fases",//no i18n
	"crm.campaign.member.status" : "Lidstatus",//no i18n
	"crm.dashboard.select.type" : "Selecteer {0}",//no i18n
	"crm.campaign.service.status":"Servicestatus",//no i18n

	"crm.label.addColumn":"Kolom toevoegen",//no i18n
	"crm.button.clear.filter":"Filter sluiten",//no i18n
	"crm.button.show.filter":"Filter tonen",//no i18n
	"crm.las.error.user.maxlimit":"U kunt maximaal 20 gebruikers selecteren.",//no i18n
	"crm.las.error.picklist.maxlimit":"U kunt maximaal 20 opties selecteren.",//no i18n

	"crm.fileuploader.message.responseerror": "Upload is mislukt", //NO I18N
	"crm.storage.create.error":"Er kunnen geen nieuwe records worden gemaakt omdat u uw maximale limiet voor gegevensopslag heeft bereikt.",//no i18n
	"crm.storage.create.error.client":"Er kunnen geen nieuwe records worden gemaakt omdat uw beheerder de maximale opslaglimiet heeft bereikt. Neem contact op met {0} om dit op te lossen.",//no i18n
	"crm.storage.avail.info":"({0} resterend van {1})",//no i18n
	"crm.storage.error.key.manage":"Beheer uw gegevensopslag",//no i18n
	"Records":"Records",//no i18n
	"crm.workflow.alert.additional.recipients" : "Extra ontvangers", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Gebruik komma\u2019s om meerdere e-mailadressen van elkaar te scheiden.", // NO I18N
	"crm.related.contact.account" : "{0} gekoppeld aan {1}",//No I18n
	"crm.allcontact.show" : "Alle {0}",//No I18n
	"crm.button.mass.show" : "Weergeven",//No I18n
	"crm.msg.custom.view.not.replied" : "Berichten die niet zijn beantwoord", //NO I18N
	"crm.msg.custom.view.replied" : "Beantwoorde berichten",//NO I18N
	"crm.workflow.select.recipients" : "Ontvangers" ,//NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Selecteer ten minste een profiel.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "U kunt een standaard profiel niet verwijderen.",//NO I18N
	"crm.inv.label.add.emails" : "E-mails toevoegen", //NO I18N
	"crm.prediction.analytics.filter.year":"Afgelopen jaar",//no i18n
	"Previous\ FY":"Vorige FY",//no i18n
	"Current\ FY":"Huidige FY",//no i18n
	"Next\ FY":"Volgende FY",//no i18n
	"Current\ FQ":"Huidige FQ",//no i18n
	"Next\ FQ":"Volgende FQ",//no i18n
	"Previous\ FQ":"Vorige FQ",//no i18n
	"crm.picklist.sample.text":"Voorbeeldtekst",//no i18n
	"crm.more.colors":"Meer kleuren",//no i18n
	"crm.button.back.alone":"Terug",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
"crm.zia.nba.feature.label":"Volgende beste actie",//no i18n
"Meeting":"Vergadering",//no i18n
"Tomorrow":"Morgen",//no i18n
"crm.gdpr.notavailable.field":"Niet beschikbaar",//no i18n
	"crm.setup.system.ziasimilarity":"Vergelijkbaarheidsaanbeveling",//no i18n
	"crm.gdpr.notavailable.field":"Niet beschikbaar",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} moet groter dan of gelijk aan {1} zijn.",
	"crux.users.selected.plural" : "{0} gebruikers geselecteerd.",
	"crux.user.selected.singular" :"{0} gebruiker geselecteerd.",
	"crux.criteria.empty.secondaryfield.module" : "Geen bijpassend {0}-veld gevonden in module {1}",
	"crux.criteria.empty.secondaryfield" : "Geen ander {0}-veld beschikbaar voor vergelijking, voer een waarde in om te vergelijken.",
	"crux.logged.in.role.definition" : "De gebruikersrol die de recordacties start",
	"zia.similarity.smartfilter.score":"Vergelijkingscore",//no i18n
	"zia.similarity.smartfilter.records":"Vergelijkbaar {0} van",//no i18n
	"zia.similarity.smartfilter.records.search":"Toon vergelijkbaar {0} van",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"U kunt niet meer dan {0} {1} selecteren",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} moet groter dan of gelijk aan {1} zijn.",
	"crux.users.selected.plural" : "{0} gebruikers geselecteerd.",
	"crux.user.selected.singular" :"{0} gebruiker geselecteerd.",
	"crux.criteria.empty.secondaryfield.module" : "Geen bijpassend {0}-veld gevonden in module {1}",
	"crux.criteria.empty.secondaryfield" : "Geen ander {0}-veld beschikbaar voor vergelijking, voer een waarde in om te vergelijken.",
	"crux.logged.in.role.definition" : "De gebruikersrol die de recordacties start",
	"crux.max.limit.unselect" : "U kunt niet meer dan {0} {1} deselecteren.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d is al geselecteerd" //NO i18N

}
